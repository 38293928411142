import React from "react";
import {
  TextButton,
  Text,
  Card,
  Box,
  Page,
  Button,
  Cell,
  Layout,
  WixDesignSystemProvider,
  Table,
  TableToolbar,
  Divider,
  Image,
  Badge,
  Tooltip,
  TableActionCell,
  Notification,
  Loader,
  Modal,
  MessageModalLayout,
  Dropdown,
  listItemSelectBuilder,
  Input,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import * as Icons from "@wix/wix-ui-icons-common";
import "./App.css";
import { ReactTagManager } from "react-gtm-ts";
import * as PayIcons from "react-payment-logos/dist/flat-rounded";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

// create an object to match:

// 名稱	內容
// direct_pay	Direct Pay
// apple_pay	Apple Pay
// google_pay_token	Google Pay Token 卡號
// google_pay_fpan	Google Pay 原始卡號
// samsung_pay	Samsung Pay
// line_pay	LINE Pay
// tsp_token	TSP Token
// jko_pay	JKOPAY
// easy_wallet	悠遊付
// atome	Atome
// pi_wallet	Pi 錢包
// plus_pay	全盈支付

const paymentMethod: any = {
  direct_pay: "Direct Pay",
  apple_pay: "Apple Pay",
  google_pay_token: "Google Pay Token",
  google_pay_fpan: "Google Pay 原始卡號",
  samsung_pay: "Samsung Pay",
  line_pay: "LINE Pay",
  tsp_token: "TSP Token",
  jko_pay: "JKOPAY",
  easy_wallet: "悠遊付",
  atome: "Atome",
  pi_wallet: "Pi 錢包",
  plus_pay: "全盈支付",
};

// create an object to match:
// -1	ERROR	交易錯誤
// 0	AUTH	銀行已授權交易，但尚未請款
// 1	OK	交易完成
// 2	PARTIALREFUNDED	部分退款
// 3	REFUNDED	完全退款
// 4	PENDING	待付款
// 5	CANCEL	取消交易

const status: any = {
  "-1": "失敗",
  0: "未捕获",
  1: "已成功",
  2: "部分退款",
  3: "已退款",
  4: "待处理",
  5: "已取消",
};

const statusIcon: any = {
  "-1": <Icons.StatusAlertSmall />,
  0: <Icons.StatusAlertSmall />,
  1: <Icons.StatusCompleteSmall />,
  2: <Icons.StatusAlertSmall />,
  3: <Icons.StatusAlertSmall />,
  4: <Icons.StatusAlertSmall />,
  5: <Icons.StatusAlertSmall />,
};

const statusDescription: any = {
  "-1": "查找更多有关付款时间线的详细信息。",
  0: "已授权但未捕获的支付。",
  1: "已成功的支付。",
  2: "已部分退款：{{formattedAmount}}",
  3: "已退款：{{formattedAmount}}",
  4: "待处理的支付。",
  5: "客户取消了支付。",
};

const statusSkin: any = {
  "-1": "danger",
  0: "neutralSuccess",
  1: "success",
  2: "warning",
  3: "warning",
  4: "neutralLight",
  5: "neutralLight",
};

// create an object to match:
// funding	int	卡片類別
// -1 = Unknown
// 0 = 信用卡 (Credit Card)
// 1 = 簽帳卡 (Debit Card)
// 2 = 預付卡 (Prepaid Card)
// type	int	卡片種類
// -1 = Unknown
// 1 = VISA
// 2 = MasterCard
// 3 = JCB
// 4 = Union Pay
// 5 = AMEX

const cardFunding: any = {
  "-1": "Unknown",
  0: "信用卡",
  1: "簽帳卡",
  2: "預付卡",
};

const cardType: any = {
  "-1": "Unknown",
  1: "VISA",
  2: "MasterCard",
  3: "JCB",
  4: "Union Pay",
  5: "AMEX",
};

const demoCharges: any = [
  {
    rec_trade_id: "TRX20231204abcXYZ",
    record_status: 1,
    original_amount: 75.5,
    details: "Online Shopping - Order #789",
    currency: "USD",
    card_info: {
      issuer_zh_tw: "",
      country: "UNITED STATES",
      country_code: "US",
      funding: 1,
      last_four: "1234",
      level: "PLATINUM",
      bank_id: "",
      bin_code: "456789",
      type: 1,
      issuer: "CHASE BANK",
    },
    payment_method: "direct_pay",
    amount: 75.5,
    cardholder: {
      name: "Emma Johnson",
      phone_number: "+1 555-123-4567",
      email: "emma.j@example.com",
      zip_code: "10001",
    },
    refunded_amount: 0.0,
    time: 1701591887098,
    card_identifier: "890cdeab12f34567a8bc901def234567",
  },
  {
    rec_trade_id: "TRX20231205XYZ123",
    record_status: 1,
    original_amount: 55.75,
    details: "Concert Tickets - Event #5678",
    currency: "EUR",
    card_info: {
      issuer_zh_tw: "",
      country: "GERMANY",
      country_code: "DE",
      funding: 0,
      last_four: "9876",
      level: "GOLD",
      bank_id: "",
      bin_code: "654321",
      type: 2,
      issuer: "DEUTSCHE BANK",
    },
    payment_method: "direct_pay",
    amount: 55.75,
    cardholder: {
      name: "Alexander Müller",
      phone_number: "+49 151 23456789",
      email: "alex.m@example.com",
      zip_code: "10115",
    },
    refunded_amount: 0.0,
    time: 1701591887098,
    card_identifier: "0123456789abcdef0123456789abcdef",
  },
  {
    rec_trade_id: "TRX20231206XYZ456",
    record_status: 1,
    original_amount: 120.75,
    details: "Product Purchase - Order #12345",
    currency: "USD",
    card_info: {
      issuer_zh_tw: "",
      country: "UNITED STATES",
      country_code: "US",
      funding: 0,
      last_four: "4321",
      level: "PLATINUM",
      bank_id: "",
      bin_code: "987654",
      type: 1,
      issuer: "CHASE BANK",
    },
    payment_method: "direct_pay",
    amount: 120.75,
    cardholder: {
      name: "Emily Davis",
      phone_number: "+1 555-987-6543",
      email: "emily.d@example.com",
      zip_code: "90210",
    },
    refunded_amount: 0.0,
    time: 1701591887098,
    card_identifier: "7890123456789012",
    status: {
      code: 1,
      description: "已成功",
      icon: "<Icons.StatusCompleteSmall />",
      status_description: "已成功的支付。",
      status_skin: "success",
    },
  },
  {
    rec_trade_id: "TRX20231211XYZ004",
    record_status: 3,
    original_amount: 75.25,
    details: "Bookstore Purchase - Transaction #98765",
    currency: "EUR",
    card_info: {
      issuer_zh_tw: "",
      country: "GERMANY",
      country_code: "DE",
      funding: 1,
      last_four: "5432",
      level: "GOLD",
      bank_id: "",
      bin_code: "765432",
      type: 2,
      issuer: "COMMERZBANK",
    },
    payment_method: "direct_pay",
    amount: 75.25,
    cardholder: {
      name: "Maria Müller",
      phone_number: "+49 151 76543210",
      email: "maria.m@example.com",
      zip_code: "10115",
    },
    refunded_amount: 0.0,
    time: 1701591887098,
    card_identifier: "3456789012345678",
    status: {
      code: 3,
      description: "已退款",
      icon: "<Icons.StatusAlertSmall />",
      status_description: "已退款：{{formattedAmount}}",
      status_skin: "warning",
    },
  },
  {
    rec_trade_id: "TRX20231212XYZ005",
    record_status: 2,
    original_amount: 150.5,
    details: "Travel Booking - Reservation #1234",
    currency: "USD",
    card_info: {
      issuer_zh_tw: "",
      country: "UNITED STATES",
      country_code: "US",
      funding: 0,
      last_four: "4567",
      level: "PLATINUM",
      bank_id: "",
      bin_code: "987654",
      type: 5,
      issuer: "AMERICAN EXPRESS",
    },
    payment_method: "direct_pay",
    amount: 150.5,
    cardholder: {
      name: "Alexandra Johnson",
      phone_number: "+1 555-765-4321",
      email: "alexandra.j@example.com",
      zip_code: "90210",
    },
    refunded_amount: 0.0,
    time: 1701591887098,
    card_identifier: "0123456789012345",
    status: {
      code: 2,
      description: "部分退款",
      icon: "<Icons.StatusAlertSmall />",
      status_description: "已部分退款：{{formattedAmount}}",
      status_skin: "warning",
    },
  },
  {
    rec_trade_id: "TRX20231213XYZ006",
    record_status: 1,
    original_amount: 80.75,
    details: "Dining Out - Restaurant Bill #5678",
    currency: "JPY",
    card_info: {
      issuer_zh_tw: "",
      country: "JAPAN",
      country_code: "JP",
      funding: 0,
      last_four: "7890",
      level: "WORLD",
      bank_id: "",
      bin_code: "123456",
      type: 3,
      issuer: "JCB CORPORATION",
    },
    payment_method: "direct_pay",
    amount: 80.75,
    cardholder: {
      name: "Takashi Tanaka",
      phone_number: "+81 90 8765 4321",
      email: "takashi.t@example.com",
      zip_code: "100-0001",
    },
    refunded_amount: 0.0,
    time: 1701591887098,
    card_identifier: "2345678901234567",
    status: {
      code: 1,
      description: "已成功",
      icon: "<Icons.StatusCompleteSmall />",
      status_description: "已成功的支付。",
      status_skin: "success",
    },
  },
];

function App() {
  ReactTagManager.init(tagManagerArgs);

  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");

  const [charges, setCharges] = React.useState([]);
  const [isDemo, setIsDemo] = React.useState(true);
  const [showCreatePaymentModal, setShowCreatePaymentModal] =
    React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [currentChargeId, setCurrentChargeId] = React.useState("");
  const [showCopyNotification, setShowCopyNotification] = React.useState(false);

  const useCreatePaymentModal = () => {
    const [selectedEmail, setSelectedEmail] = React.useState("");
    const [selectedChargeId, setSelectedChargeId] = React.useState("");
    return (
      <MessageModalLayout
        onCloseButtonClick={() => setShowCreatePaymentModal(false)}
        primaryButtonOnClick={() => setShowCreatePaymentModal(false)}
        secondaryButtonOnClick={() => setShowCreatePaymentModal(false)}
        primaryButtonText={"建立付款"}
        primaryButtonProps={{
          prefixIcon: <Icons.CreditCardSmall />,
        }}
        secondaryButtonText={"取消"}
        secondaryButtonProps={{
          prefixIcon: <Icons.X />,
        }}
        title={"建立付款"}
      >
        <Box height={"300"} direction="vertical" gap="SP2">
          <Dropdown
            // extract all email from charges
            options={[
              ...new Set(
                charges
                  .map((charge: any) => charge.cardholder.email)
                  .filter((v: any) => v)
              ),
            ].map((v) => {
              return { id: v, value: v };
            })}
            onSelect={(option) => setSelectedEmail(String(option.id))}
            selectedId={selectedEmail}
          />
          <Dropdown
            // extract all cards from charges with the same email
            disabled={!selectedEmail}
            options={[
              ...new Set(
                charges.filter(
                  (charge: any) => charge.cardholder.email === selectedEmail
                )
              ),
            ].map((v: any) => {
              if (!v.card_info) {
                return { id: v.rec_trade_id, value: "Unknown" };
              }
              return listItemSelectBuilder({
                // give the transaction id as the id
                id: v.rec_trade_id,
                prefix: <Box>{getIcon(v.card_info.type)}</Box>,
                title: `${
                  cardType[v.card_info.type] || v.card_info.type
                } •••• ${v.card_info.last_four}`,
                // show issuer & card funding if available
                subtitle: `${
                  v.card_info.issuer === ""
                    ? ""
                    : `由 ${v.card_info.issuer} 發行的`
                } ${cardFunding[v.card_info.funding] || v.card_info.funding}`,
              });
            })}
            onSelect={(option) => setSelectedChargeId(String(option.id))}
            selectedId={selectedChargeId}
          />
          <Input
            disabled={!selectedChargeId}
            placeholder="請輸入金額"
            size="large"
            prefix={
              <Input.Affix>
                <Text size="small">NT$</Text>
              </Input.Affix>
            }
          />
        </Box>
      </MessageModalLayout>
    );
  };

  React.useEffect(() => {
    if (token) {
      window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=c134d1c7-0467-4f43-8a95-0fcd1da4f8e6&redirectUrl=https://certifiedcode.wixsite.com/tappay-dashboard/_functions/@certifiedcode/base-backend/auth`;
    }
  }, [token]);

  React.useEffect(() => {
    getCharges();
  }, []);

  // log changes with charge
  React.useEffect(() => {
    console.log(charges);
  }, [charges]);

  const getCharges = () => {
    if (!instance) {
      return;
    }
    fetch(
      "https://api.certifiedcode.us/v1/payments/dashboard/c134d1c7-0467-4f43-8a95-0fcd1da4f8e6/charges",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: instance,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (isDemo) {
          setCharges(demoCharges);
        } else {
          setCharges(data.trade_records);
        }
        setLoading(false);
      });
  };

  function getIcon(cardType: any) {
    switch (cardType) {
      case 1:
        return <PayIcons.Visa width={"30"} />;
      case 2:
        return <PayIcons.Mastercard width={"30"} />;
      case 3:
        return <PayIcons.Jcb width={"30"} />;
      case 4:
        return <PayIcons.Unionpay width={"30"} />;
      case 5:
        return <PayIcons.Amex width={"30"} />;
      default:
        return <></>;
    }
  }

  const Charges = ({ data }: any) => {
    // example charges[i]
    //   {
    //     "rec_trade_id": "D202310127NrAny",
    //     "record_status": 1,
    //     "order_number": "",
    //     "merchant_id": "certifiedcode_ESUN",
    //     "is_rba_verified": false,
    //     "cap_millis": 1697124600000,
    //     "original_amount": 19000,
    //     "details": "hoplus196",
    //     "currency": "TWD",
    //     "partial_card_number": "424242-4242",
    //     "card_info": {
    //         "issuer_zh_tw": "",
    //         "country": "UNITED KINGDOM",
    //         "country_code": "GB",
    //         "funding": 0,
    //         "last_four": "4242",
    //         "level": "",
    //         "bank_id": "",
    //         "bin_code": "424242",
    //         "type": 1,
    //         "issuer": ""
    //     },
    //     "three_domain_secure": true,
    //     "payment_method": "direct_pay",
    //     "bank_transaction_start_millis": 1697111319123,
    //     "bank_result_code": "00",
    //     "bank_result_msg": "",
    //     "amount": 19000,
    //     "bank_transaction_id": "TP070N1R0A1N1Y",
    //     "pay_by_instalment": false,
    //     "merchant_name": "",
    //     "is_captured": true,
    //     "transaction_method_details": {
    //         "transaction_method_reference": "REQUEST",
    //         "transaction_method": "THREE_DOMAIN_SECURE"
    //     },
    //     "bank_transaction_end_millis": 1697111319123,
    //     "cardholder": {
    //         "national_id": "",
    //         "member_id": "",
    //         "address": "",
    //         "bank_member_id": "",
    //         "name": "Test Test",
    //         "phone_number": "60651845",
    //         "email": "test@test.com",
    //         "zip_code": ""
    //     },
    //     "auth_code": "150559",
    //     "refunded_amount": 0,
    //     "app_name": "",
    //     "kyc_info": {
    //         "is_kyc_verified": false,
    //         "kyc_verification_merchant_id": ""
    //     },
    //     "time": 1697111319068,
    //     "pay_by_redeem": false,
    //     "card_identifier": "bf13281bb838451bbf5cfd9287ead74f"
    // }

    const columns = [
      // order of columns from the above object:
      // example:       { title: "Name", render: (row: any) => row.name },

      //      Amount
      {
        title: "金額",
        render: (row: any) => `$${row.amount}`,
        width: "50px",
        align: "end",
      },
      {
        title: "",
        render: (row: any) => (
          <Text skin="disabled" size="small">{`${row.currency}`}</Text>
        ),
        width: "25px",
      },
      {
        title: "",
        render: (row: any) => (
          <Tooltip
            content={statusDescription[row.record_status]
              .replace("{formattedAmount}", `$${row.refunded_amount || 0}`)
              .replace("{{bank_result_msg}}", row?.bank_result_msg || "")}
          >
            <Badge
              skin={statusSkin[row.record_status]}
              size="medium"
              suffixIcon={statusIcon[row.record_status]}
            >
              {status[row.record_status]}
            </Badge>
          </Tooltip>
        ),
        width: "100px",
      },
      // ​Payment method
      {
        title: "支付方式",
        render: (row: any) => {
          if (row.payment_method === "direct_pay" && row.card_info) {
            const icon = getIcon(row.card_info.type);
            return (
              <Tooltip
                content={
                  // show, e.g. 由 {card_info.issuer} 發行的 Visa 信用卡 •••• 4242
                  row.card_info.issuer === ""
                    ? `${
                        cardType[row.card_info.type] || row.card_info.type
                      } •••• ${row.card_info.last_four}`
                    : `由 ${row.card_info.issuer} 發行的 ${
                        cardType[row.card_info.type] || row.card_info.type
                      } •••• ${row.card_info.last_four}`
                }
              >
                <Box align="center" verticalAlign="middle" gap="6px">
                  {icon}
                  {/* // card ending */}
                  <Text size="small">
                    {cardFunding[row.card_info.funding] ||
                      row.card_info.funding}{" "}
                    •••• {row.card_info.last_four}
                  </Text>
                </Box>
              </Tooltip>
            );
          }
          return paymentMethod[row.payment_method] || row.payment_method;
        },
        width: "150px",
      },
      // Description
      { title: "描述", render: (row: any) => row.details, width: "200px" },
      // Customer
      {
        title: "客戶",
        render: (row: any) => row.cardholder.email,
        width: "250px",
      },
      // Date
      {
        title: "日期",
        render: (row: any) =>
          // to convert to date format
          new Date(row.time).toLocaleString(),
        width: "250px",
      },
      {
        render: (row: any) => (
          <TableActionCell
            secondaryActions={[
              {
                text: "操作",
                icon: <></>,
                onClick: () => {},
                disabled: true,
              },
              {
                text: "复制付款 ID",
                icon: <></>,
                onClick: () => {
                  navigator.clipboard.writeText(row.rec_trade_id);
                  setShowCopyNotification(true);
                },
              },
              {
                divider: true,
              },
              {
                text: "连接",
                icon: <></>,
                onClick: () => {},
                disabled: true,
              },
              {
                text: "查看客户",
                icon: <></>,
                onClick: () => {},
              },
              {
                text: "查看付款详情",
                icon: <></>,
                onClick: () => {},
              },
            ]}
            moreActionsTooltipText="更多操作"
          />
        ),
      },
      // {
      //   render: (row: any) =>
      //     row.expandable ? (
      //       <TableActionCell
      //         size="small"
      //         primaryAction={{
      //           text: !row.expanded ? "Expand" : "Collapse",
      //           onClick: () => {
      //             setCurrentChargeId(row.rec_trade_id);
      //           },
      //         }}
      //       />
      //     ) : (
      //       ""
      //     ),
      // },
    ];

    const renderRowDetails = (row: any) => {
      if (row.expandable) {
        return <Loader></Loader>;
      }
    };

    return (
      <Layout justifyItems="center">
        <Cell>
          <Card hideOverflow={false} stretchVertically={true}>
            <Card.Header
              title="所有付款"
              suffix={
                <Button
                  size="small"
                  priority="primary"
                  prefixIcon={<Icons.Add />}
                  onClick={() => setShowCreatePaymentModal(true)}
                >
                  建立付款
                </Button>
              }
            />
            <Table
              // horizontalScroll
              stickyColumns={3}
              rowDetails={(row) => renderRowDetails(row)}
              // onRowClick={(row) => {
              //   row.expanded =
              //     row.expanded === undefined ? true : !row.expanded;
              // }}
              data={data}
              // @ts-ignore
              columns={columns}
              rowVerticalPadding="medium"
            >
              {/* <TableToolbar>
                <TableToolbar.ItemGroup position="start">
                  <TableToolbar.Item>
                    <Text size="small">
                      <b>
                        {selectedFirstTablePage - 1 + 1} -{" "}
                        {selectedFirstTablePage - 1 + 8}
                      </b>{" "}
                      out of {data?.length} charges
                    </Text>
                  </TableToolbar.Item>
                </TableToolbar.ItemGroup>
              </TableToolbar> */}
              <Table.Content />
            </Table>
          </Card>
        </Cell>
        {/* <Cell>
          <Pagination
            currentPage={selectedFirstTablePage}
            totalPages={Math.ceil(charges.length / 8)}
            onChange={_handleFirstTablePageChange}
          />
        </Cell> */}
      </Layout>
    );
  };

  const NoCharges = () => {
    const renderTableToolbar = () => (
      <TableToolbar>
        <TableToolbar.Title>所有付款</TableToolbar.Title>
      </TableToolbar>
    );

    const renderEmptyState = () => (
      <Table.EmptyState
        title="您尚未有任何付款"
        subtitle="開始接受付款後，您將在此處看到付款。"
        image={
          <Image
            height={120}
            width={120}
            src="https://www.wix-style-react.com/storybook/EmptyState_Generic1.svg"
            transparent
          />
        }
      >
        <TextButton prefixIcon={<Icons.Add />}>建立收款連結</TextButton>
      </Table.EmptyState>
    );

    return (
      <Card>
        {/* @ts-ignore */}
        <Table>
          {renderTableToolbar()}
          {renderEmptyState()}
        </Table>
      </Card>
    );
  };

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Modal
        isOpen={showCreatePaymentModal}
        onRequestClose={() => setShowCreatePaymentModal(false)}
      >
        {useCreatePaymentModal()}
      </Modal>
      <Notification
        show={showCopyNotification}
        theme="success"
        onClose={() => setShowCopyNotification(false)}
      >
        <Notification.TextLabel>
          <Text weight="bold">已複製付款 ID</Text>
        </Notification.TextLabel>
        <Notification.CloseButton />
      </Notification>
      <Page>
        <Page.Header
          title="付款"
          subtitle="通過 Experience 處理的支付將顯示在此處。"
          showBackButton
        />
        {token ? (
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text="Loading" />
            </Box>
          </Page.Content>
        ) : loading === true ? (
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text="正在查找您的交易" />
            </Box>
          </Page.Content>
        ) : (
          <Page.Content>
            {charges.length === 0 ? <NoCharges /> : <Charges data={charges} />}
          </Page.Content>
        )}
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
